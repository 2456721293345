var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('section',{directives:[{name:"aware",rawName:"v-aware",value:({
    appear: {
      once: true,
      threshold: [0.5, 0.75, 1],
    },
  }),expression:"{\n    appear: {\n      once: true,\n      threshold: [0.5, 0.75, 1],\n    },\n  }"}],staticClass:"card-slider",class:{ 'is-slider': _vm.isSlider },on:{"appear":_vm.onAppear}},[_c('div',{staticClass:"card-slider-inner h-wrapper"},[_c('h2',{staticClass:"card-slider__title h3",domProps:{"innerHTML":_vm._s(_vm.content.title)}}),(_vm.type === 'speakers')?_c('ul',{ref:"list",staticClass:"card-slider__list"},_vm._l((_vm.content.items),function(item,index){return _c('li',{key:("card-slider__item--" + index),ref:"item",refInFor:true,staticClass:"card-slider__item"},[_c('SpeakersCard',{key:("card-speaker-" + (item.title) + "-" + (Math.random()
            .toString(36)
            .substr(2, 9))),staticClass:"content-search__grid__card",attrs:{"content":item}})],1)}),0):_c('ul',{ref:"list",staticClass:"card-slider__list"},_vm._l((_vm.content.items),function(item,index){return _c('li',{key:("card-slider__item--" + index),ref:"item",refInFor:true,staticClass:"card-slider__item"},[_c('g-card',{attrs:{"content":item}})],1)}),0),(_vm.content.url)?_c('g-btn',{staticClass:"card-slider__btn",attrs:{"content":{
        url: _vm.content.url,
        label: 'View All',
      }}}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }