




























































































































































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import SpeakersCard from '@/components/speakers/Card.vue'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'card-slider',
  props: {
    content: Object,
    type: String,
  },
  setup(props) {
    const isSlider = props?.content?.items.length > 4
    const list = ref<null | HTMLElement>(null)
    const total = ref(0)
    const current = ref(0)
    let flickity

    const item = ref<HTMLElement | null>(null)

    // console.log(isSlider && window.innerWidth > 780)

    onMounted(async () => {
      const Flickity = await import(
        /* webpackChunkName: 'vendor-flickity' */ 'flickity'
      ).then(module => module.default)

      flickity = new Flickity(list.value as HTMLElement, {
        cellSelector: '.card-slider__item',
        pageDots: false,
        prevNextButtons: true,
        cellAlign: 'left',
        wrapAround: false,
        contain: true,
        draggable: isSlider || window.innerWidth <= 1024,
      })

      const updateProgress = (index, total) => {
        if (index >= total - 4) {
          flickity.nextButton.element.disabled = true
        } else {
          flickity.nextButton.element.disabled = false
        }
      }

      total.value = flickity.cells.length
      current.value = flickity.selectedIndex

      flickity.on('change', () => {
        updateProgress(flickity.selectedIndex, flickity.cells.length)
      })

      flickity.on('dragStart', () => {
        if (list.value) {
          list.value.classList.add('is-dragging')
        }
      })
      flickity.on('dragEnd', () => {
        if (list.value) {
          list.value.classList.remove('is-dragging')
        }
      })
    })

    onUnmounted(() => {
      flickity.destroy()
    })

    const onAppear = isInViewport => {
      const tl = gsap.timeline()
      tl.set(item.value, {
        opacity: 0,
        y: 20,
      })
      if (isInViewport) {
        tl.to(item.value, {
          duration: 1,
          opacity: 1,
          stagger: 0.1,
          y: 0,
        })
      }
    }

    return {
      current,
      isSlider,
      list,
      total,
      onAppear,
      item,
    }
  },
  components: {
    SpeakersCard,
  },
})
